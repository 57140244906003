import { Typography } from "@material-ui/core"
import React, { PropsWithChildren, ReactElement } from "react"
import { JsonLd } from "react-schemaorg"
import { BlogPosting } from "schema-dts"

import { PageContext } from "../interfaces/pageContext"
import { Layout } from "./Layout"

interface Props {
  location: Location
  pageContext: PageContext
  title: string
  category?: string
}

function BlogPost(props: PropsWithChildren<Props>): ReactElement {
  const { location, pageContext, title, category, children } = props
  pageContext.title = title
  return (
    <Layout location={location} pageContext={pageContext}>
      <JsonLd<BlogPosting>
        item={{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          headline: title,
        }}
      />
      <article className="ion-padding">
        {category && (
          <Typography align="center" variant="body2">
            {category}
          </Typography>
        )}
        <Typography align="center" variant="h1">
          {title}
        </Typography>
        {children}
      </article>
    </Layout>
  )
}

export default BlogPost
