import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Patchwork"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Patchwork ist alles andere als OLDSCHOOL! Die Kombination der verschiedenen Muster aus
            Blumen, Punkten, Streifen und den unterschiedlichen Farben machen den Look einzigartig.
            Patchwork kommt nie aus der Mode. Das Aniston CASUAL Blusenkleid im Patchwork Design aus
            Crêpe ist durch die Muster des Animal-Print sowie Blümchen und Punkten ein wahres
            Kunstwerk. Es wirkt durch die tolle Passform sehr elegant. Ein Must-Have für jeden
            Patchwork-Liebhaber. Die Influencerin Ángela Rozas Saiz beispielsweise kombiniert ihren
            wunderschönen Patchwork-Mantel mit einem weißen Kleid und einer{" "}
            <Link href="/umhaengetaschen/" underline="always">
              Crossbody-Bag
            </Link>{" "}
            von Louis Vuitton.
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B2zamSfgcr0/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608618337&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33713773?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Aniston CASUAL Blusenkleid",
                price: 42.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24607820729&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/16212619?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Boysen's Maxirock",
                price: 59.99,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=24608619397&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32273731?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Boysen's Jerseyrock",
                price: 49.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25323060271&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34411327?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Sheego Druckkleid",
                price: 49.99,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608859905&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32662983?w=1875&h=2500",
                    title: "",
                  },
                ],
                name: "Sandwich Patchwork-Kleid",
                price: 49.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608386993&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/d327e13c-da48-54ad-9ccb-333bf020d7a7?w=1500&h=2000",
                    title: "",
                  },
                ],
                name: "heine CASUAL Druckrock",
                price: 44.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25471680319&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35823473?w=2500&h=2489",
                    title: "",
                  },
                ],
                name: "Superdry Strickpullover",
                price: 79.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25323060313&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34410862?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Sheego Langarmbluse",
                price: 44.99,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
